
/**
 * Map view for all items, including batteries and vwa displays
 * with latitude and longitude coordinates
 */
import MapHelper from "@/mixins/map/MapHelper";
import { MainProjectSectionDeviceView } from "@/model/MainProject/MainProjectSection";

import UserAccess from "@/model/User/UserAccess";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { Projects } from "@/services/projectService";
import router from "@/router";

@Component({
  name: "ItemMap",

  computed: {
    ...mapGetters(["getUserAccess", "isUserAdmin", "isUserProjectManager", "isConstructionManager"]),

    ...mapGetters({
      getAllBatteryDevicesInProject: "getAllBatteryDevicesInProject"
    })
  },
  methods: {
    ...mapActions(["getAllDevicesFromBackEnd"])
  }
})
export default class ItemMap extends Mixins(MapHelper) {
  readonly getUserAccess!: UserAccess;
  readonly isUserAdmin!: boolean;
  readonly isUserProjectManager!: boolean;
  readonly isConstructionManager!: boolean;

  getAllBatteryDevicesInProject!: Function;
  getAllDevicesFromBackEnd!: Function;

  showItems = true;
  showBatteries = false;
  showVwa = false;
  loading = true;
  interval = 0;
  globalItemsWithCoordinates: any = [];

  async created() {
    this.loading = true;

    await this.getAllDevicesFromBackEnd();
    this.fetchItemCoordinates();
    //fetch item coordinates every 2 minutes
    this.interval = setInterval(async () => {
      this.fetchItemCoordinates();
    }, 240000);
  }

  fetchItemCoordinates() {
    this.loading = true;
    this.showItems = true;
    this.globalItemsWithCoordinates = this.getAllItems();
  }

  destroyed() {
    clearInterval(this.interval);
  }

  /** Method getAllItems() to fetch all items with coordinates
   * calling api request to fetch project data
   * processing sections within each active projects with deviceStateOne and deviceStateTwo
   * */
  getAllItems() {
    let itemsWithCoordinates: any = {
      item: [],
      battery: [],
      vwa: []
    };

    //const itemIds: Array<any> = [];
    var count = 0;

    let request: any = null;
    // separate view for admins and for user assigned in projects
    if (this.isUserAdmin || this.isUserProjectManager || this.isConstructionManager) {
      request = Projects.getAllActiveProjectsWithSections();
    } else {
      request = Projects.getAllActiveProjectsForUserWithSections((router as any).app.$keycloak.tokenParsed.sub);
    }

    request.then((response: any) => {
      if (response.status === 200) {
        if (response.data.projectList.length > 0) {
          response.data.projectList.forEach(async (project: any) => {
            count++;

            // each device in sections
            project.sections.forEach((section: any) => {
              // stateDetails
              var sectionStateDetails = section.state.stateDetails;

              // *********
              // DeviceOne
              // *********
              if (
                sectionStateDetails.deviceStateOne.latitude != 0 &&
                sectionStateDetails.deviceStateOne.longitude != 0
              ) {
                if (section.displayOneType && section.displayOneType.name == "vwa") {
                  itemsWithCoordinates["vwa"].push(
                    this.formatInformation(sectionStateDetails.deviceStateOne, {
                      kilometrage: section.kilometrage,
                      device: "vwa",
                      serialnumber: section.boardOne,
                      batteryVoltage: sectionStateDetails.deviceStateOne.batteryTerminalValue,
                      prewarningLamp: sectionStateDetails.deviceStateOne.prewarningLamp,
                      cbRadio: sectionStateDetails.deviceStateOne.cbRadio
                    })
                  );
                } else {
                  itemsWithCoordinates["item"].push(
                    this.formatInformation(sectionStateDetails.deviceStateOne, {
                      kilometrage: section.kilometrage,
                      device: "item",
                      serialnumber: section.boardOne,
                      batteryVoltage: sectionStateDetails.deviceStateOne.batteryTerminalValue,
                      prewarningLamp: sectionStateDetails.deviceStateOne.prewarningLamp,
                      cbRadio: sectionStateDetails.deviceStateOne.cbRadio
                    })
                  );
                }
              }

              // *********
              // DeviceTwo
              // *********
              if (
                sectionStateDetails.deviceStateTwo.latitude != 0 &&
                sectionStateDetails.deviceStateTwo.longitude != 0
              ) {
                if (section.displayTwoType && section.displayTwoType.name == "vwa") {
                  itemsWithCoordinates["vwa"].push(
                    this.formatInformation(sectionStateDetails.deviceStateTwo, {
                      kilometrage: section.kilometrage,
                      device: "vwa",
                      serialnumber: section.boardTwo,
                      batteryVoltage: sectionStateDetails.deviceStateTwo.batteryTerminalValue,
                      prewarningLamp: sectionStateDetails.deviceStateTwo.prewarningLamp,
                      cbRadio: sectionStateDetails.deviceStateTwo.cbRadio
                    })
                  );
                } else {
                  itemsWithCoordinates["item"].push(
                    this.formatInformation(sectionStateDetails.deviceStateTwo, {
                      kilometrage: section.kilometrage,
                      device: "item",
                      serialnumber: section.boardTwo,
                      batteryVoltage: sectionStateDetails.deviceStateTwo.batteryTerminalValue,
                      prewarningLamp: sectionStateDetails.deviceStateTwo.prewarningLamp,
                      cbRadio: sectionStateDetails.deviceStateTwo.cbRadio
                    })
                  );
                }
              }
            });

            // *********
            // Batteries
            // *********
            itemsWithCoordinates["battery"] = this.getBatteriesByProjectId(itemsWithCoordinates["battery"], project.id);
            if (count === response.data.projectList.length - 1) {
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
        }
      }
    });

    return itemsWithCoordinates;
  }

  /** get filteredItems() method returns an array of filtered items based on each device type */
  get filteredItems(): Array<any> {
    var allItems = this.globalItemsWithCoordinates["item"];
    if (!this.showItems) {
      allItems = [];
    }
    //concatenate batteries to allitems if showBatteries option is true
    if (this.showBatteries) {
      allItems = allItems.concat(this.globalItemsWithCoordinates["battery"]);
    }
    //concatenate vwa displays to allitems if showVwa option is true
    if (this.showVwa) {
      allItems = allItems.concat(this.globalItemsWithCoordinates["vwa"]);
    }
    return allItems;
  }

  /** fetching battery devices in projects */
  private getBatteriesByProjectId(itemsWithCoordinates: Array<any>, projectId: number) {
    let batteriesInProject = this.getAllBatteryDevicesInProject(projectId);

    if (batteriesInProject.length > 0) {
      batteriesInProject.forEach((value: any) => {
        itemsWithCoordinates.push(this.formatBatteryInformation(value));
      });
    }
    return itemsWithCoordinates;
  }

  formatInformation(item: MainProjectSectionDeviceView, customData: any) {
    return {
      device: customData.device,
      status: item.status,
      batteryVoltage: customData.batteryVoltage,
      height: item.displayType?.height || 48,
      width: item.displayType?.width || 48,
      kilometer: customData.kilometrage,
      mainBoardId: customData.serialnumber ? customData.serialnumber : item.id,
      picture: item.actualPicture,
      prewarning: customData.prewarningLamp,
      radio: customData.cbRadio,
      position: {
        lat: item.latitude ? item.latitude : null,
        lng: item.longitude ? item.longitude : null
      },
      brightness: item.brightness,
      charginCurrent: item.charging
    };
  }

  formatBatteryInformation(battery: any) {
    return {
      device: "Battery",
      deviceId: battery.id,
      emnifyIccId: battery.emnifyIccId,
      position: {
        lat: battery.latitude ? battery.latitude : null,
        lng: battery.longitude ? battery.longitude : null
      },
      gpsLastUpdated: battery.gpsLastUpdated,
      serialNumber: battery.serialNumber,
      type: battery.type.name
    };
  }

  readyMarker(index: any, allItems: any) {
    if (index == allItems.length - 1) {
      this.loading = false;
    }
  }
}
